import React, { useCallback, useEffect } from 'react';
import { navigate } from 'gatsby';
import Page from 'components/Layout/Page';
import { Container, Grid, Typography, Snackbar } from '@mui/material';
import MentoringApplyForm from 'components/Forms/MentoringApply';
import { SandboxApplyFormData } from 'src/models/forms';
import * as Sentry from '@sentry/react';
import { useSandboxApply } from 'hooks/useSandboxApply';
import { useBooleanState } from '@lubycon/react';
import { mentoringApplyPageLogger } from 'utils/loggers';
import sandboxData from 'data/sandbox.json';
import { SandboxApplyStatus } from 'models/sandbox';

const SandboxApplyPage = () => {
  const [isOpenErrorSnackbar, openErrorSnackbar, closeErrorSnackbar] = useBooleanState();
  const [loading, startLoading, endLoading] = useBooleanState();
  const { apply, status } = useSandboxApply();
  const handleSubmit = useCallback(
    async (data: SandboxApplyFormData) => {
      mentoringApplyPageLogger.click('mentoring_project_form_apply', {
        group: sandboxData.group,
        position: data.position,
        careerYear: data.careerYear,
      });

      if (status !== SandboxApplyStatus.신청기간) {
        return;
      }

      startLoading();
      try {
        await apply(data);
        navigate('/mentoring/apply-result');
      } catch (e) {
        openErrorSnackbar();
        Sentry.captureException(e, {
          user: {
            email: data.email,
            username: data.name,
          },
        });
      } finally {
        endLoading();
      }
    },
    [startLoading, endLoading, openErrorSnackbar, apply, status]
  );

  const handleSnackbarClose = useCallback(() => closeErrorSnackbar(), [closeErrorSnackbar]);

  useEffect(() => {
    mentoringApplyPageLogger.view();
  }, []);

  return (
    <Page title="루비콘 샌드박스 신청하기" description="루비콘 샌드박스에 오신 것을 환영합니다!">
      <Container>
        <Grid container justifyContent="center" sx={{ marginTop: 15, marginBottom: 4 }}>
          <Grid item xs={12} md={8}>
            <Typography
              variant="h4"
              component="h1"
              gutterBottom={true}
              sx={{ fontWeight: 'bold', textAlign: 'center' }}
            >
              루비콘 샌드박스 신청하기
            </Typography>
          </Grid>
        </Grid>
        <Grid container justifyContent="center">
          <Grid item xs={12} md={8}>
            <MentoringApplyForm loading={loading} onSubmit={handleSubmit} />
          </Grid>
        </Grid>
      </Container>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={isOpenErrorSnackbar}
        autoHideDuration={5000}
        onClose={handleSnackbarClose}
        message="루비콘 샌드박스 신청에 약간의 문제가 있어요!😢 문제가 계속 된다면 help@lubycon.io으로 알려주세요"
      />
    </Page>
  );
};

export default SandboxApplyPage;
