import React, { ReactNode } from 'react';
import { FormControl, FormLabel } from '@mui/material';
interface Props {
  label: string;
  required?: boolean;
  children: ReactNode;
  error?: boolean;
}
const TextFieldset = ({ label, required = false, children, error = false }: Props) => {
  return (
    <FormControl component="fieldset" required={required} fullWidth={true} error={error} sx={{ marginBottom: 3 }}>
      <FormLabel sx={{ marginBottom: 1 }}>{label}</FormLabel>
      {children}
    </FormControl>
  );
};

export default TextFieldset;
