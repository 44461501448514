import React, { ReactNode } from 'react';
import { Grid, Typography } from '@mui/material';

const Section = ({ title, children }: { title?: string; children: ReactNode }) => {
  return (
    <Grid item xs={12} sx={{ marginBottom: 3 }}>
      {title && (
        <Typography
          component="h2"
          variant="h5"
          sx={{
            marginBottom: 3,
            fontWeight: 'bold',
            fontSize: {
              md: 'h5.fontSize',
              xs: 'h6.fontSize',
            },
          }}
        >
          {title}
        </Typography>
      )}
      {children}
    </Grid>
  );
};

export default Section;
