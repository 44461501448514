import React, { useCallback, useMemo } from 'react';
import {
  Grid,
  FormControlLabel,
  Checkbox,
  TextField,
  Radio,
  Button,
  RadioGroup,
  Typography,
  Theme,
  Box,
  Paper,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import Section from 'components/Forms/MentoringApply/Section';
import Fieldset from 'components/Forms/MentoringApply/Fieldset';
import { SandboxApplyFormData } from 'models/forms';
import { positions } from 'constants/applyForm';
import { useSandboxApply } from 'src/hooks/useSandboxApply';
import Emoji from 'src/components/Utils/Emoji';
import { SxProps } from '@mui/system';
import { LoadingButton } from '@mui/lab';
import {
  SandboxApplyStatus,
  프론트질문리스트,
  백엔드질문리스트,
  디자인질문리스트,
  필수질문리스트,
  선택질문리스트,
} from 'models/sandbox';
import RouteLink from 'src/components/Utils/RouteLink';

const styles: Record<string, SxProps<Theme>> = {
  description: {
    margin: '-1rem 0px 2rem',
    color: 'rgba(0, 0, 0, 0.54)',
    fontStyle: 'italic',
  },
  paper: {
    padding: {
      md: 4,
      xs: 2,
    },
    '&+&': {
      marginTop: 4,
    },
  },
};

interface Props {
  onSubmit?: (data: SandboxApplyFormData) => void;
  loading?: boolean;
}
const MentoringApplyForm = ({ onSubmit, loading = false }: Props) => {
  const { register, handleSubmit, errors, watch } = useForm();
  const formValues = watch();
  const { status } = useSandboxApply();

  const onSubmitHandler = useCallback(
    data => {
      const formData = { ...data } as SandboxApplyFormData;
      onSubmit?.(formData);
    },
    [onSubmit]
  );

  const questionsByPosition = useMemo(() => {
    switch (formValues.position) {
      case '웹 프론트엔드 개발자':
        return 프론트질문리스트;
      case '백엔드 개발자':
        return 백엔드질문리스트;
      case 'UI/UX 디자이너':
        return 디자인질문리스트;
      default:
        return [];
    }
  }, [formValues.position]);

  if (status !== SandboxApplyStatus.신청기간) {
    return (
      <>
        <Typography sx={{ textAlign: 'center', marginBottom: 3 }} gutterBottom>
          지금은 샌드박스 신청기간이 아니에요.
          <br />
          다음 기회에 다시 신청해주세요! <Emoji emoji="🙇‍♂️" name="thanks" />
        </Typography>
        <Button href="/" color="primary" variant="contained" fullWidth>
          홈으로
        </Button>
      </>
    );
  }

  return (
    <Grid
      component="form"
      container
      noValidate
      autoComplete="off"
      onSubmit={(e: any) => e.preventDefault()}
      sx={{
        '& .MuiFormLabel-asterisk': {
          fontWeight: 'bold',
          color: 'primary.main',
        },
      }}
    >
      <Paper sx={styles.paper}>
        <Section title="연락에 필요한 기본정보를 작성해주세요.">
          <Fieldset label="이메일" required={true} error={Boolean(errors.email)}>
            <TextField
              name="email"
              type="email"
              placeholder="john@lubycon.io"
              variant="outlined"
              inputRef={register({
                required: {
                  value: true,
                  message: '이메일을 입력해주세요',
                },
                pattern: {
                  value: /\b[\w.-]+@[\w.-]+\.\w{2,4}\b/,
                  message: '올바른 이메일을 입력해주세요',
                },
              })}
              error={Boolean(errors.email)}
              helperText={errors.email?.message ?? ''}
            />
          </Fieldset>
          <Fieldset label="이름" required={true} error={Boolean(errors.name)}>
            <TextField
              name="name"
              placeholder="홍길동"
              variant="outlined"
              inputRef={register({
                required: {
                  value: true,
                  message: '이름을 입력해주세요',
                },
                pattern: {
                  value: /[a-zA-Z가-힣]+/,
                  message: '올바른 이름을 입력해주세요',
                },
              })}
              error={Boolean(errors.name)}
              helperText={errors.name?.message ?? ''}
            />
          </Fieldset>
          <Fieldset label="휴대폰 번호 (-는 빼주세요)" required={true} error={Boolean(errors.phone)}>
            <TextField
              name="phone"
              type="tel"
              placeholder="01012341234"
              variant="outlined"
              inputRef={register({
                required: {
                  value: true,
                  message: '휴대폰 번호를 입력해주세요',
                },
                pattern: {
                  value: /^01\d\d{4}\d{4}$/,
                  message: '올바른 휴대폰 번호를 입력해주세요',
                },
              })}
              error={Boolean(errors.phone)}
              helperText={errors.phone?.message}
            />
          </Fieldset>
        </Section>
        <Section title="현재 무슨 일을 하고 계시나요?">
          <Fieldset label="포지션" required={true}>
            <RadioGroup row defaultValue={positions[0]}>
              {positions.map(position => (
                <FormControlLabel
                  key={position}
                  name="position"
                  label={position}
                  value={position}
                  control={<Radio />}
                  inputRef={register()}
                  sx={{
                    borderRadius: 1,
                    marginRight: '7px',
                    paddingRight: '9px',
                    '&:hover': {
                      backgroundColor: 'action.hover',
                    },
                  }}
                />
              ))}
            </RadioGroup>
          </Fieldset>
          <Fieldset label="이 포지션의 경력이 어떻게 되시나요? (연차)" required={true}>
            <TextField name="careerYear" type="number" variant="outlined" inputRef={register()} />
          </Fieldset>
        </Section>
        <Section title="여러분을 알려주세요.">
          {필수질문리스트.map(question => (
            <Fieldset key={question.key} label={question.label} required={question.required}>
              <TextField multiline name={question.key} variant="outlined" inputRef={register()} />
            </Fieldset>
          ))}
        </Section>
        <Section title={`${formValues.position}에 대한 질문이에요.`}>
          {questionsByPosition.map(question => (
            <Fieldset key={question.key} label={question.label} required={question.required}>
              <TextField multiline name={question.key} variant="outlined" inputRef={register()} />
            </Fieldset>
          ))}
        </Section>
      </Paper>

      <Paper sx={styles.paper}>
        <Section title="이제부터는 선택 항목이에요.">
          <Typography sx={styles.description}>
            처음 만나는 사람들과 팀을 이루고 짧은 시간 동안 하나의 서비스를 완성하는 것은 쉽지 않은 일이니만큼,
            메이트들이 여러분에 대해 더 많이 알 수록 시너지를 낼 수 있는 더 좋은 팀을 꾸릴 수 있을 거예요.
          </Typography>
          {선택질문리스트.map(question => (
            <Fieldset key={question.key} label={question.label} required={question.required}>
              <TextField multiline name={question.key} variant="outlined" inputRef={register()} />
            </Fieldset>
          ))}
          <Typography component="h3" variant="h6" sx={{ margin: '0 0 1rem' }} gutterBottom={true}>
            포트폴리오/깃헙/블로그 링크 (선택)
          </Typography>
          <Typography sx={styles.description}>
            파일인 경우에는{' '}
            <Box
              component="a"
              sx={{ color: 'primary.main', fontWeight: 'bold' }}
              href="mailto:help@lubycon.io"
              target="_blank"
              rel="noreferrer"
            >
              help@lubycon.io
            </Box>
            로 보내주세요!
          </Typography>
          <Fieldset label={'링크 1'} required={false}>
            <TextField type="url" name="link1" variant="outlined" inputRef={register()} />
          </Fieldset>
          <Fieldset label={'링크 2'} required={false}>
            <TextField type="url" name="link2" variant="outlined" inputRef={register()} />
          </Fieldset>
          <Fieldset label={'링크 3'} required={false}>
            <TextField type="url" name="link3" variant="outlined" inputRef={register()} />
          </Fieldset>
        </Section>
      </Paper>
      <Paper sx={styles.paper}>
        <Typography component="h3" variant="h6" sx={{ margin: '0 0 1rem' }} gutterBottom={true}>
          인재풀 정보 저장 동의
        </Typography>
        <Fieldset
          label="인재풀 확보에 동의해주신다면, 만약 샌드박스 프로젝트 진행 도중에 인원 충원이 필요한
            경우에 가장 먼저 연락을 드릴 수 있습니다! (1년 간 저장)"
        >
          <FormControlLabel
            name="agreeUsePrivacyData"
            label="동의합니다"
            inputRef={register()}
            control={<Checkbox />}
          />
        </Fieldset>
      </Paper>

      <LoadingButton
        onClick={handleSubmit(onSubmitHandler)}
        loading={loading}
        variant="contained"
        fullWidth={true}
        disabled={status !== SandboxApplyStatus.신청기간}
        sx={{ fontSize: 'h6.fontSize', fontWeight: 'bold', marginTop: 4 }}
      >
        {status !== SandboxApplyStatus.신청기간 ? '마감되었습니다' : '지원하기'}
      </LoadingButton>
      <Typography variant="body2" sx={{ textAlign: 'center', width: '100%', marginTop: 1 }}>
        지원하시면 루비콘 샌드박스의{' '}
        <RouteLink to="https://lubycon.notion.site/c8211205d9ad4289a81a378a96d1e9ef">이용약관</RouteLink>,{' '}
        <RouteLink to="https://lubycon.notion.site/aab838ff5cdb4513b8cfa4a683203819">개인정보처리방침</RouteLink>,{' '}
        <RouteLink to="https://www.notion.so/lubycon/18d369fc52324788a6de8b260c909e95">환불규정</RouteLink>에 동의하는
        것으로 간주합니다.
      </Typography>
    </Grid>
  );
};

export default MentoringApplyForm;
